<div
  class="container"
  [class.left-panel-active]="leftPanelActive"
  [class.left-panel-active]="leftPanelActive"
  [ngClass]="!showFooter ? '' : 'fix-page'"
>
  <div class="row custom-row otp-row">
    <div class="panel-left p_panel-left display-none">
      <app-order-sidebar
        (changeleftPanelValue)="displayLeftPanel($event)"
      ></app-order-sidebar>
    </div>
    <div
      class="panel-right p_panel-right otp-right-panel otp-mobile mobile-move"
    >
      <!-- <div class="left-panel-show" (click)="showLeftPanel()">
                    <figure><img src="assets/images/arrow-left.png" alt=""></figure>
                    <span> Order</span>
                </div> -->
      <div class="otp-mobile">
        <div class="ordered-items">
          <div class="item-list">
            <div class="item-info">
              <div class="item-name">
                <button class="close-track" (click)="setBackToOrder()"></button>

                <!-- <span class="order-heading">
                                    <a (click)=" setBackToOrder()"><img src="../../../../assets/images/icon-arrow-left.svg" /></a>{{pageTitle}}
                                </span> -->
                <span class="order-heading">
                  {{ pageTitle }}
                </span>
                <ng-container
                  *ngIf="
                    (userSettings?.supportPhone &&
                      userSettings?.supportPhone != 'null') ||
                    (userSettings?.supportEmail &&
                      userSettings?.supportEmail != 'null') ||
                    (userSettings?.supportUrl &&
                      userSettings?.supportUrl != 'null')
                  "
                >
                  <a
                    (click)="openHelpModal()"
                    (mouseover)="mouseEnter('addColor')"
                    (mouseout)="mouseLeave('removeColor')"
                    [ngStyle]="{ color: Helpcolor ? getColor : '#828282' }"
                    >Help ?</a
                  >
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <!--- otp section -->
        <div class="otp-mob-wrp scroll-content">
          <div class="product-detail-mob" *ngIf="currentSelectedItem && !verifyOtp">
            <div class="prod-img">
              <img
                src="{{
                  currentSelectedItem && currentSelectedItem.productImageUrl
                    ? currentSelectedItem.productImageUrl
                    : 'assets/images/item-image.jpg'
                }}"
                alt="product image"
              />
            </div>
            <div class="prod-info">
              <h4>
                {{ currentSelectedItem ? currentSelectedItem.productName : "" }}
              </h4>
              <p>
                {{ rupeeSymbol }}
                <span>
                  {{
                    currentSelectedItem
                      ? transformDecimal(currentSelectedItem.customerPrice)
                      : ""
                  }}</span
                >
              </p>
            </div>
          </div>
          <!-- confirmed section -->
          <div class="otp-section">
            <div>
              <p>
                Please enter the 4 digit code sent to
                <strong
                  >{{
                    orderDetails && orderDetails.shippingAddress
                      ? verifyOtpscreen ? orderDetails.shippingAddress.emailmask : orderDetails.shippingAddress.email
                      : ""
                  }}
                </strong>
                and
              </p>
              <p>
                <strong>
                  +91
                  {{
                    orderDetails && orderDetails.shippingAddress
                      ? verifyOtpscreen ? orderDetails.shippingAddress.contactPhonemask : orderDetails.shippingAddress.contactPhone 
                      : ""
                  }}</strong
                >
                to confirm the action.
              </p>

              <!-- <p>OTP sent to <strong>  +91 {{ (orderDetails && orderDetails.shippingAddress) ? orderDetails.shippingAddress.contactPhone:'' }}</strong> and <strong>{{ (orderDetails && orderDetails.shippingAddress) ? orderDetails.shippingAddress.email:'' }}  </strong>.</p> -->

              <!-- <h4>6 - DIGIT CODE</h4> -->
              <form>
                <div class="form-group">
                  <!-- <input type="password" [ngClass]="otpMessage ? 'form-control error-control':'form-control'" (input)="callVerifyOtp($event)" (keypress)="numberOnly($event)" maxlength="6" placeholder="XXXXXX" [(ngModel)]="otpInput" name="otpInput">
                                                    <span class="error">{{ otpMessage }}</span>  -->
                  <div class="otp-container">
                    <ng-otp-input
                      #ngOtpInput
                      (onInputChange)="callVerifyOtp($event)"
                      *ngIf="showOtpComponent"
                      [config]="config"
                    ></ng-otp-input>
                    <ng-container *ngIf="showLoader">
                      <span class="otp-span">
                        <div
                          class="loader-css opt-loader-css"
                          [ngStyle]="{ 'border-top-color': getColor }"
                        ></div>
                      </span>
                    </ng-container>
                  </div>

                  <span class="error" *ngIf="otp && otpMessage"
                    >{{ otpMessage }}
                  </span>
                  <span class="error infosentotp" *ngIf="otpSentinfo"
                    >A new OTP has been sent</span
                  >
                </div>

                <!--  showResendOtp-->

                <p class="resend-count" *ngIf="!showResendOtp">
                  Didn’t receive the OTP?
                  <a
                    class="send-otp-btn"
                    style="color: #bdbdbd; pointer-events: none;"
                    >RESEND</a
                  >&nbsp;&nbsp;&nbsp;<span>{{ countDownValue }}</span>
                </p>
                <div class="otp-group-btn">
                  <ng-container *ngIf="showResendOtp">
                    <p class="resend-count">
                      Didn’t receive the OTP?<a
                        href="javascript:void(0);"
                        (click)="resendOtp()"
                        class="send-otp-btn"
                        [ngStyle]="{ color: getColor }"
                      >
                        RESEND
                      </a>
                    </p>
                  </ng-container>
                </div>
              </form>
            </div>
            <!-- *ngIf="showLoader" -->
          </div>
        </div>
        <!-- <div class="mob-footer">
                    <app-header></app-header>
                </div> -->

        <div class="btn-bottom" [ngClass]="!showFooter ? '' : 'fix-bottom'">
          <div class="btn-bottom-arrow">
            <a class="arrow-top" (click)="showFooterClick()" *ngIf="ifarrowTop"
              ><img src="https://ik.imagekit.io/2gwij97w0o/POE/arrow-top.svg"
            /></a>
            <a
              class="arrow-top"
              (click)="hideFooterClick()"
              *ngIf="ifarrowBottom"
              ><img src="https://ik.imagekit.io/2gwij97w0o/POE/arrow-bottom.svg"
            /></a>
          </div>
          <input
            type="button"
            value="Cancel"
            class="btn-cancel"
            (click)="setBackToOrder()"
          />
          <ng-container>
            <ng-container *ngIf="loading; else continueButton">
              <figure
                class="d-flex btn-loader btn-continue"
                [ngStyle]="{ background: getColor }"
              >
                <div class="loader-css"></div>
              </figure>
            </ng-container>
            <ng-template style="border: 1px solid black;" #continueButton>
              <input
                class="btn-continue"
                type="button"
                (click)="verifyOtp($event)"
                value="confirm"
                [disabled]="!disableButton"
                [ngStyle]="{
                  'background-color': disableButton ? getColor : ''
                }"
              />
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="loader-layover" *ngIf="showLoader">
            <figure>
                <img src="assets/images/loader.gif" alt="Loader">
            </figure>
    </div> -->

<div class="mob-footer" *ngIf="showFooter">
  <div class="footer-underline">
    <p>
      <ng-container *ngFor="let keys of footerKey; let i = index">
        <a target="_blank" (click)="open_ace_com(keys[1])">
          {{ keys[0] }}
        </a>
      </ng-container>
    </p>
  </div>

  <div *ngIf="userSettings?.websiteUrl">
    <p class="second-para">
      <a (click)="open_ace_com(userSettings?.websiteUrl)">
        <span class="mobile-store-link"> Go to store </span>
      </a>
    </p>
  </div>
</div>

<div class="poweredby-mobile" *ngIf="showFooter">
  <a href="https://www.eshopbox.com/" target="_blank">
    <p style="word-wrap: no-wrap;">
      <a href="https://www.eshopbox.com/" target="_blank">
        <img
          src="https://ik.imagekit.io/2gwij97w0o/POE/eshopbox-monogram.svg"
          alt=""/></a
      >Powered by Eshopbox
    </p>
  </a>
</div>

<div
  class="help-box"
  (click)="closeHelpModal()"
  [class.helpShow]="isHelpOpendelay"
></div>
<div
  class="main-helpbox"
  [ngStyle]="{ display: isHelpOpendelay ? 'block' : '' }"
  [class.helpShow]="isHelpOpen"
>
  <div
    class="help-top-box"
    *ngIf="
      (userSettings?.supportPhone && userSettings?.supportPhone != 'null') ||
      (userSettings?.supportEmail && userSettings?.supportEmail != 'null') ||
      (userSettings?.supportUrl && userSettings?.supportUrl != 'null')
    "
  >
    <h2>Help ?</h2>
    <button (click)="closeHelpModal()"></button>
  </div>

  <div class="help-bottom-box">
    <div
      class="text-block"
      *ngIf="userSettings?.supportUrl && userSettings?.supportUrl != 'null'"
    >
      <h1>Support URL</h1>
      <a (click)="openSite(userSettings?.supportUrl)">{{
        userSettings?.supportUrl
      }}</a>
    </div>
    <div
      class="text-block"
      *ngIf="userSettings?.supportEmail && userSettings?.supportEmail != 'null'"
    >
      <h1>Support email</h1>
      <a
        href="mailto:{{
          userSettings && userSettings.supportEmail
            ? userSettings.supportEmail
            : ''
        }}"
        >{{ userSettings?.supportEmail }}</a
      >
    </div>
    <div
      class="text-block"
      *ngIf="userSettings?.supportPhone && userSettings?.supportPhone != 'null'"
    >
      <h1>Support phone number</h1>
      <a href="tel:{{ userSettings?.supportPhone }}">{{
        userSettings?.supportPhone
      }}</a>
    </div>
  </div>
</div>
