import { Component, OnDestroy, OnInit, Input, OnChanges } from "@angular/core";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { NavigationEnd, Router } from "@angular/router";
import {
  ROUTES,
  RETURN_TYPE_EXCHANGE,
  RETURN_TYPE_REPLACE,
  RUPEE_SYMBOL,
  DATE_FORMAT,
  FORWARD_STATUS,
  REVERSE_STATUS,
  STATUS_ACTIVE_CLASS,
  CONSTANTS,
  REFUND_STATUS,
  RESOLUTION_CODE
} from "../shared/constants/constants";
import { Subscription, Observable } from "rxjs";
import { select, Store } from "@ngrx/store";
// import { GetOrder } from "./actions/order-placed.actions";
// import { getCurrentOrder, getSelectedItem } from "./reducers/selectors";
import {
  AuthService,
  LocalStorageService,
  OrderReturnService,
  OrderService
} from "../shared/services/index";
import { AlertService } from "../shared/services";
import { AppState } from "../app.state";
import { DatePipe, DecimalPipe, KeyValue } from "@angular/common";
import { DeviceDetectorService } from "ngx-device-detector";
import { SharedService } from "../shared/services/shared.service";
import {
  GetOrder,
  GetUserSettings,
  OrderPlacedActionTypes
} from "./actions/order-placed.actions";
import { getCurrentOrder, getUserSettings } from "./reducers/selectors";
import { ScrollingService } from "../shared/services/scrolling.service";
import * as moment from "moment";
import { InformationPopoverComponent } from "../shared/components/information-popover/information-popover.component";
import { MatDialog } from "@angular/material";
@Component({
  selector: "app-order-placed",
  templateUrl: "./order-placed.component.html",
  styleUrls: ["./order-placed.component.scss"],
  providers: [DatePipe, DecimalPipe]
})
export class OrderPlacedComponent implements OnInit, OnDestroy {
  dateFormat = DATE_FORMAT;
  leftPanelActive = false;
  // orderUrlIDs;
  arr_seeAllUpdates = [];
  orderData;
  selectedItem;
  // orderId: any;
  rupeeSymbol = RUPEE_SYMBOL;
  showLoader = true;
  subscriptions$ = new Subscription();
  userSettingssubscriptions$ = new Subscription();
  selectedItemDetails: any;
  modifyStatusLog: any = [];
  reverseTimelineStatusLog: any = [];
  forwardStatusArray = FORWARD_STATUS.FORWARD_ORDER_STATUS_TIMELINE;
  forwardIntransitStatus = FORWARD_STATUS.INTRANSIT_ALL_STATUS;
  cancellationStatusArray = FORWARD_STATUS.CANCEL_STATUS;
  reverseStatusArray = REVERSE_STATUS.REVERSE_ORDER_STATUS_TIMELINE;
  reverseStatusRename = REVERSE_STATUS.REVERSE_ORDER_STATUS_RENAME;
  remarkStatusArray = REVERSE_STATUS.REMARK_REVERSE_STATUS;
  remarkDummyStatusArray = REVERSE_STATUS.REMARK_REVERSE_STATUS_DUMMY;
  reverseIntransitStatus = REVERSE_STATUS.INTRANSIT_ALL_STATUS;
  statusActiveClass = STATUS_ACTIVE_CLASS;
  refundStatusArray = REFUND_STATUS.REFUND_STATUS_TIMELINE;
  reverseTimelineCancelButtonStatus =
    REVERSE_STATUS.REVERSE_STATUS_FOR_CANCEL_RETURN_BUTTON;
  toolTipMessage: string;
  remarkMessage: string;
  compareForwardStatus: any[];
  refundTimelineStausLog: any;
  accessToken: string;
  showMiddleTimlineActive = false;
  userSettings: any;
  orderId: any;
  orderItemId: any;
  returnTypeExchange = RETURN_TYPE_EXCHANGE;
  returnTypeReplace = RETURN_TYPE_REPLACE;
  returnCancelledTimelineStatus = REVERSE_STATUS.RETURN_CANCELLED_STATUS;
  resolutionMessage: string;
  isMobile: boolean;
  isTablet: boolean;
  shippedStatusCount = 0;
  public isHelpOpen = false;
  public isHelpOpendelay = false;
  public isSeeUpdatesOpen = false;
  public isSeeUpdatesOpendelay = false;

  statusData: boolean;
  /*---------------------------------Start-------------------------*/
  placed = FORWARD_STATUS.FORWARD_PLACED_STATUS;
  packed = FORWARD_STATUS.FORWARD_PACKED_STATUS;
  shipped = FORWARD_STATUS.FORWARD_SHIPPED_STATUS;
  Delivery_failed = FORWARD_STATUS.FORWARD_SHIPPED_DELIVERYFAILD_STATUS;
  out_for_delivery = FORWARD_STATUS.FORWARD_OUTFORDILEVRY_STATUS;
  intransit_status = FORWARD_STATUS.FORWARD_ALL_INTRANSIT_STATUS;
  shipment_delay = FORWARD_STATUS.FORWARD_SHIPMENT_DELAY_STATUS;
  delivered = FORWARD_STATUS.FORWARD_DELIVERED_STATUS;

  /*---------------------------------Start REVERSE_STATUS-------------------------*/

  requested = REVERSE_STATUS.REVERSE_RETURN_REQUESTED_STATUS;
  approved = REVERSE_STATUS.REVERSE_RETURN_APPROVED_STATUS;
  pickup = REVERSE_STATUS.REVERSE_RETURN_OUTFOR_PICKUP_STATUS;
  fail_delivery = REVERSE_STATUS.REVERSE_RETURN_PICK_UP_FAILED_STATUS;
  pickup_pending = REVERSE_STATUS.REVERSE_RETURN_PICK_UP_PENDING_STATUS;
  reverse_all_status = REVERSE_STATUS.REVERSE_STATUS_TIMELINE;

  /*---------------------------------Start WAREHOUSE_STATUS-------------------------*/

  warehouse_delivered = REVERSE_STATUS.WAREHOUSE_STATUS;
  order_placed = REVERSE_STATUS.ORDER_PLACED;
  shipped_delay_message: string;
  shipment_delay_status: string;
  pickup_delay_status: string;
  pickup_delay_message: string;
  deliveredDate: any;
  footerKey: [string, unknown][];
  color: boolean;
  getColor: any;
  show_forward_reverse_timeline: boolean;

  cancellationStatusArrayNew = FORWARD_STATUS.CANCEL_STATUS_NEW;
  newExchangeItem: string;
  windowDate: any;
  statusContent: string;
  status_content: string;
  bgcolor: any;
  isReadMore = true;
  remark =
    "THis is long text replaced with read more/less link example in Angular application paragraph text shown here continue continue THis is long text replaced w";
  expandedIndex: number;
  isCancelOpen: boolean;
  todaysDate: string;
  EddtodaysDate: string;
  currentindex: any;
  CancelItemToster: boolean = false;
  browserSafari: boolean;
  forwardTrackingTimelinestatusLog: unknown[];
  resolutionCode: any;
  resolutionCodeContent: any;
  failedDeliveryRequest: boolean;
  returnItemToster: boolean;
  //private titleService: Title
  isDesktopDevice: any;
  failedDeliverynegative: boolean;
  editdetailsRequest: boolean;
  invoiceUrl: any;
  SecureinvoiceUrl: any;
  userdetailsinfo: boolean = false;
  verifyOTP : boolean = false;
  // dialog: MatDialog;
  constructor(
    private preventScroll: ScrollingService,
    private router: Router,
    private store: Store<AppState>,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private titleService: Title,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private orderService: OrderService,
    private orderReturnService: OrderReturnService,
    private dialog: MatDialog,
    private auth: AuthService
  ) {
    this.expandedIndex = -1;
    this.verifyOTP = this.auth.getTokenType() == 'partial' ? true : false;
    localStorage.removeItem('partialVisit')
  }

  ngOnInit() {
    this.isDesktopDevice = this.deviceService.isDesktop();
    var isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf("Apple") > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf("CriOS") == -1 &&
      navigator.userAgent.indexOf("FxiOS") == -1;

    var el = document.createElement("p");
    this.browserSafari = isSafari;
    // console.log("Is it Safari:", this.browserSafari);

    if (LocalStorageService.get("cancelItemRequestTrue")) {
      this.CancelItemToster = true;
    }
    if (LocalStorageService.get("returnRequest")) {
      this.returnItemToster = true;
    }
    if (LocalStorageService.get("failedDeliverTostermsg")) {
      this.failedDeliveryRequest = true;
    }
    if (LocalStorageService.get("editdetailsTostermsg")) {
      this.editdetailsRequest = true;
    }
    if (LocalStorageService.get("failedDelivernegativeMsg")) {
      this.failedDeliverynegative = true;
    }
    let date = new Date();
    this.todaysDate = this.datePipe.transform(date, this.dateFormat);
    // this.datePipe.transform(date, this.dateFormat);
    this.EddtodaysDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    // this.todaysDate = date;

    // if (!localStorage.getItem('foo')) {
    //   localStorage.setItem('foo', 'no reload')
    //   window.location.reload();
    // } else {
    //   localStorage.removeItem('foo')
    // }

    this.statusData = this.sharedService.getData();
    if (this.statusData) {
      this.leftPanelActive = this.statusData;
    }

    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.flushSomeStorageData();
    this.orderId = this.route.snapshot.paramMap.get("orderId")
      ? this.route.snapshot.paramMap.get("orderId")
      : "";
    this.orderItemId = this.route.snapshot.paramMap.get("orderItemId")
      ? this.route.snapshot.paramMap.get("orderItemId")
      : "";
    /*Get order data from state*/
    this.store.dispatch(new GetOrder(this.orderId));
    this.subscriptions$.add(
      this.store.pipe(select(getCurrentOrder)).subscribe(result1 => {
        if (result1) {
          this.showLoader = false;
          this.orderData = result1;
        } else {
          this.orderData = [];
          this.selectedItemDetails = [];
        }
      })
    );
    setTimeout(() => {
      LocalStorageService.flush("failedDelivernegativeMsg");
      this.failedDeliverynegative = false;
    }, 10000);

    this.subscriptions$ = this.store
      .select(state => state.orderPlaced.orderPlaced)
      .subscribe(result => {
        if (result) {
          this.orderData = result;
          // this.orderData['riskScore'] = 'high';
          // this.orderData['riskScoreReason'] = ['Address issue'];
          // this.orderData['pincodeServiceable'] = false;
          // console.log("this.orderData ",this.orderData );

          LocalStorageService.set("orderTotal", this.orderData.orderTotal);
        }
      });

    this.resolutionCode = LocalStorageService.get("resolutionCode");
    this.resolutionCodeContent = LocalStorageService.get(
      "resolutionCodeContent"
    );
    if (LocalStorageService.get("failedDeliverTostermsg")) {
      this.store.dispatch(new GetOrder(this.orderId));
      this.subscriptions$.add(
        this.store.pipe(select(getCurrentOrder)).subscribe(result1 => {
          if (result1) {
            this.orderData = result1;
            this.showLoader = false;
            // console.log("this.orderData",this.orderData);

            // LocalStorageService.set('channelSlug',this.orderData.channelSlug);
            // this.defaultSelectedItem(this.orderData);
          } else {
            this.orderData = [];
            this.selectedItemDetails = [];
          }
        })
      );
      setTimeout(() => {
        LocalStorageService.flush("failedDeliverTostermsg");
        this.failedDeliveryRequest = false;
      }, 10000);
    }

    /*Get user settings data from state*/
    // this.userSettingssubscriptions$ = this.store.select(state => state.orderPlaced.userSettings).subscribe(result => {
    //   if (result) {

    //     this.userSettings = result;
    //     this.getColor = this.userSettings.actionColor;
    //     LocalStorageService.set('getColor', this.getColor)
    //     console.log('result',result);

    //     this.footerKey = Object.entries(this.userSettings.footerLinks);
    //   }
    // });

    this.store.dispatch(new GetUserSettings());
    this.subscriptions$.add(
      this.store.pipe(select(getUserSettings)).subscribe(result => {
        if (result) {
          this.userSettings = result;
          this.getColor = this.userSettings.actionColor;
          if (this.userSettings.footerLinks) {
            this.footerKey = Object.entries(this.userSettings.footerLinks);
          }
          this.bgcolor = this.userSettings.noticeBackgroundColor;
          this.titleService.setTitle("Track Order");
        } else {
          this.userSettings = [];
        }
      })
    );
    setTimeout(() => {
      this.closetosterclick();
      this.returnItemToster = false;
      this.CancelItemToster = false;
      LocalStorageService.flush("returnRequest");
      LocalStorageService.flush("cancelItemRequestTrue");
      LocalStorageService.flush("editdetailsTostermsg");
      this.editdetailsRequest = false;
    }, 10000);
  }

  editdetailsevent(event) {
    this.editdetailsRequest = true;
    setTimeout(() => {
      this.closetosterclick();
      this.returnItemToster = false;
      this.CancelItemToster = false;
      LocalStorageService.flush("returnRequest");
      LocalStorageService.flush("cancelItemRequestTrue");
      LocalStorageService.flush("editdetailsTostermsg");
      this.editdetailsRequest = false;
    }, 10000);
  }
  getStyleWithoutOpacity() {
    return {
      border: "1px solid" + this.userSettings.actionColor,
      color: this.userSettings.actionColor
      //  opacity:0.6,
    };
  }
  getStyletakeaction() {
    return {
      // background: this.userSettings.actionColor,
      border: "1px solid" + this.userSettings.actionColor,
      color: this.userSettings.actionColor
      //  opacity:0.6,
    };
  }
  getStyleWithOpacity() {
    return {
      background: this.userSettings.actionColor,
      opacity: 0.05
    };
  }

  expandRow(index: number): void {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }
  /**
   * move to cancellation screen
   * @method moveTocancellation
   */
  public moveToCancellation(itemId) {
    if (status === "intransit") {
      this.alertService.showError(
        CONSTANTS.ALERT_MESSAGES.CANCEL_ITEM_INTRANSIT_STATUS_MESSAGE
      );
    } else {
      this.router.navigate([ROUTES.ORDER_CANCEL, this.orderId, itemId]);
    }
  }

  /**
   * ON Select Item show item details
   */
  public onSelectShowOrderItemDetail(item) {
    if (
      item &&
      (item.latestStatus === "failed_delivery" ||
        item.latestStatus === "rto_failed")
    ) {
      if (item.timeLine != "forwardTrackingTimeline")
        item.latestStatus =
          item.reverseTrackingTimeline.statusLog[
            item.reverseTrackingTimeline.statusLog.length - 2
          ].status;
      else if (
        item.timeLine == "forwardTrackingTimeline" &&
        item.latestStatus === "rto_failed"
      )
        item.latestStatus =
          item.forwardTrackingTimeline.statusLog[
            item.forwardTrackingTimeline.statusLog.length - 2
          ].status;
    }
    if (item && !item.latestStatus && item.orderItemStatus) {
      item.latestStatus = item.orderItemStatus.toLowerCase();
    }
    // this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, item.orderItemID]);
    this.modifyStatusLog = null;
    this.reverseTimelineStatusLog = null;
    this.refundTimelineStausLog = null;
    //forward tracking timeline
    if (
      item.forwardTrackingTimeline &&
      item.timeLine === "forwardTrackingTimeline"
    ) {
      let oldData = Object.assign(item.forwardTrackingTimeline.statusLog);
      this.seeUpdateModifyArray(oldData);
      this.forwardTrackingTimelinestatusLog = this.removeDuplicateUsingFilter(
        item.forwardTrackingTimeline.statusLog,
        false
      );

      this.modifyStatusLog = this.modifyStatusArray(
        item.forwardTrackingTimeline.statusLog,
        this.forwardStatusArray
      );
      this.shippedStatusCount = 0;
      this.modifyStatusLog.compareStatus.forEach(element => {
        if (this.forwardIntransitStatus.includes(element)) {
          this.shippedStatusCount++;
        }
      });
    }

    //reverse tracking timeline
    else if (
      item.reverseTrackingTimeline &&
      item.timeLine === "reverseTrackingTimeline"
    ) {
      this.seeUpdateModifyArray(item.reverseTrackingTimeline.statusLog);
      // this.forwardTrackingTimelinestatusLog = this.removeDuplicateUsingFilter(item.reverseTrackingTimeline.statusLog, false);
      // item.reverseTrackingTimeline.statusLog = this.removeDuplicateUsingFilter(
      //   item.reverseTrackingTimeline.statusLog,
      //   false
      // );
      this.reverseTimelineStatusLog = this.modifyStatusArray(
        item.reverseTrackingTimeline.statusLog,
        this.reverseStatusArray
      );
    } else if (
      item.reverseTrackingTimeline &&
      item.timeLine === "returnTrackingTimeline"
    ) {
      this.seeUpdateModifyArray(item.reverseTrackingTimeline.statusLog);
      // this.forwardTrackingTimelinestatusLog = this.removeDuplicateUsingFilter(item.reverseTrackingTimeline.statusLog, false);
      // item.reverseTrackingTimeline.statusLog = this.removeDuplicateUsingFilter(
      //   item.reverseTrackingTimeline.statusLog,
      //   false
      // );
      this.reverseTimelineStatusLog = this.modifyStatusArray(
        item.reverseTrackingTimeline.statusLog,
        this.reverseStatusArray
      );
    }

    //refund timeline
    else if (item.refundTimeLine && item.timeLine === "refundTimeLine") {
      item.refundTimeLine.statusLog = this.removeDuplicateUsingFilter(
        item.refundTimeLine.statusLog,
        true
      );
      this.refundTimelineStausLog = this.modifyStatusArray(
        item.refundTimeLine.statusLog,
        this.refundStatusArray
      );
    }

    item.modifyStatusLog = [];
    item.reverseTimelineStatusLog = [];
    item.refundTimelineStausLog = [];
    item.modifyStatusLog.push(this.modifyStatusLog);
    item.reverseTimelineStatusLog.push(this.reverseTimelineStatusLog);
    item.refundTimelineStausLog.push(this.refundTimelineStausLog);
    LocalStorageService.flush("processOrderItemDetails");
    LocalStorageService.set("processOrderItemDetails", item);

    this.selectedItemDetails = item;
    this.invoiceUrl = this.selectedItemDetails.invoiceUrl;
    this.SecureinvoiceUrl = this.sanitizer.bypassSecurityTrustUrl(
      this.invoiceUrl
    );

    // console.log("dfsdfdf",this.selectedItemDetails);

    this.showLoader = false;

    for (var value of this.selectedItemDetails.forwardTrackingTimeline
      .statusLog) {
      if (value.status == "delivered") {
        let deliveredDate = value.updatedAt;
        // this.deliveredDate = this.datePipe.transform(
        //   deliveredDate,
        //   this.dateFormat);
        deliveredDate = deliveredDate.replace("T", " ");
        this.deliveredDate = deliveredDate;
      }
      if (this.Delivery_failed.includes(value.status)) {
        LocalStorageService.set("failed_delivery_remark", value.remark);
      }
    }
    if (this.selectedItemDetails.refundTimeLine) {
      if (
        this.selectedItemDetails.refundTimeLine.refundMode ==
        "originalPaymentMode"
      ) {
        this.selectedItemDetails.refundTimeLine.refundMode =
          "original Payment Mode";
      } else {
        this.selectedItemDetails.refundTimeLine.refundMode;
      }
    }
  }

  // seeUpdatesData: Map<any, any> = new Map();
  // seeUpdatesDataCancel: Map<any, any> = new Map();
  seeUpdatesData: {};
  seeUpdatesDataCancel: {};
  // Variable to maintain order of Map while looping using ngFor
  public originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };

  // public seeUpdateModifyArray(trackingData, timeline?: string) {
  //   // console.log("rr",trackingData);
  //   if(!timeline) this.seeUpdatesData.clear();
  //   this.seeUpdatesDataCancel.clear();
  //   for (let data of trackingData) {
  //     let trackingStatus: string = data["status"];
  //     if (this.seeUpdatesData.has(trackingStatus) || this.seeUpdatesDataCancel.has(trackingStatus)) {
  //       let particularStatusData = this.seeUpdatesData.get(trackingStatus);
  //       particularStatusData.push(data);
  //       timeline ? this.seeUpdatesDataCancel.set(trackingStatus, particularStatusData) : this.seeUpdatesData.set(trackingStatus, particularStatusData);
  //     } else {
  //       let particularStatusData: any = [];
  //       particularStatusData.push(data);
  //       timeline ? this.seeUpdatesDataCancel.set(trackingStatus, particularStatusData) : this.seeUpdatesData.set(trackingStatus, particularStatusData);
  //     }
  //   }
  //   console.log("---------this.seeUpdatesData------",this.seeUpdatesData)
  //   console.log("---------this.seeUpdatesDataCancel------",this.seeUpdatesDataCancel)
  // }

  public seeUpdateModifyArray(trackingData, timeline?: string) {
    // console.log("rr",trackingData);
    if (!timeline) this.seeUpdatesData = {};
    this.seeUpdatesDataCancel = {};
    let prevTrackingStatus;
    trackingData.forEach((data, index) => {
      let trackingStatus: string = data["status"] + index;
      if (index != 0) {
        timeline
          ? (prevTrackingStatus = Object.keys(this.seeUpdatesDataCancel)[
              Object.keys(this.seeUpdatesDataCancel).length - 1
            ])
          : (prevTrackingStatus = Object.keys(this.seeUpdatesData)[
              Object.keys(this.seeUpdatesData).length - 1
            ]);
        if (prevTrackingStatus.startsWith(data["status"])) {
          timeline
            ? this.seeUpdatesDataCancel[prevTrackingStatus].push(data)
            : this.seeUpdatesData[prevTrackingStatus].push(data);
        } else {
          let particularStatusData: any = [];
          particularStatusData.push(data);
          timeline
            ? (this.seeUpdatesDataCancel[trackingStatus] = particularStatusData)
            : (this.seeUpdatesData[trackingStatus] = particularStatusData);
        }
      } else {
        let particularStatusData: any = [];
        particularStatusData.push(data);
        timeline
          ? (this.seeUpdatesDataCancel[trackingStatus] = particularStatusData)
          : (this.seeUpdatesData[trackingStatus] = particularStatusData);
      }
    });
  }

  formatStatus(status) {
    return status.replace(/[0-9]/g, "");
  }

  public modifyStatusArray(statusLogs, statusArray) {
    this.modifyStatusLog = [];
    this.compareForwardStatus = [];
    for (let i = 0; i < statusLogs.length; i++) {
      this.compareForwardStatus.push(statusLogs[i]["status"]);
      if (statusArray.includes(statusLogs[i]["status"])) {
        this.modifyStatusLog.push(statusLogs[i]["status"]);
      }
    }
    const finalArray = {
      statusLength: this.modifyStatusLog.length,
      compareStatus: this.compareForwardStatus
    };
    return finalArray ? finalArray : null;
  }

  ngOnDestroy() {
    if (this.subscriptions$) {
      this.subscriptions$.unsubscribe();
    }

    if (this.userSettingssubscriptions$) {
      this.userSettingssubscriptions$.unsubscribe();
    }
  }

  removeDuplicateUsingFilter(arr, condition) {
    if (condition === true) {
      const unique_array = Array.from(
        arr.reduce((m, t) => m.set(t.status, t), new Map()).values()
      );
      return unique_array;
    } else {
      const arrNew = this.sortArray(arr, "asc");
      const unique_array = this.distinctArray(arrNew);
      return unique_array;
    }

    // console.log("sort", arrNew);
    // const unique_array =  Array.from(arrNew.reduce((m, t) => m.set(t.status, t), new Map()).values());
    // console.log("uni", unique_array);
  }

  distinctArray(data) {
    return Array.from(new Set(data.map(arr => arr.status))).map(status => {
      let updatedAt = data.filter(updatedArr => updatedArr.status === status);
      updatedAt = updatedAt.sort(function(a, b) {
        return Date.parse(a.updatedAt) - Date.parse(b.updatedAt);
      });
      return {
        remark: updatedAt[updatedAt.length - 1].remark,
        status: data.find(updatedArr => updatedArr.status === status).status,
        updatedAt: updatedAt[updatedAt.length - 1].updatedAt,
        location: updatedAt[updatedAt.length - 1].location,
        revisedExpectedDeliveryDate:
          updatedAt[updatedAt.length - 1].revisedExpectedDeliveryDate
      };
    });
    // console.log(unique);
  }

  sortArray(arr, type) {
    if (type === "asc") {
      return arr.sort(function(a, b) {
        return <any>new Date(a.updatedAt) - <any>new Date(b.updatedAt);
      });
    } else {
      return arr.sort(function(a, b) {
        return <any>new Date(b.updatedAt) - <any>new Date(a.updatedAt);
      });
    }
  }

  /**
   * move to Return screen
   * @method moveToReturnProcess
   */
  public moveToReturnProcess(itemId) {
    this.router.navigate([ROUTES.ORDER_REFUND_EXCHANGE, this.orderId, itemId]);
    // this.router.navigate([ROUTES.ORDER_RETURN, this.orderId, itemId]);
    LocalStorageService.flush("itemDetails");
    LocalStorageService.flush("exchangeItemDetails");
    LocalStorageService.flush("actionValue");
    LocalStorageService.flush("refundMode");
    LocalStorageService.flush("refundDetails");
    LocalStorageService.flush("returnItemRequest");
    LocalStorageService.flush("actionType");
    LocalStorageService.flush("defaultSelected");
  }

  /*Left Panel Show Hide*/
  showLeftPanel() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("top-header");
    this.leftPanelActive = false;
  }

  displayLeftPanel(value) {
    this.leftPanelActive = true;
    // console.log("left", this.leftPanelActive);
  }

  reverseToolAndMessage(latestStatus) {
    const reverseTimeline = this.selectedItemDetails.reverseTrackingTimeline
      .statusLog;
    const returnTypeText =
      this.selectedItemDetails.returnType === this.returnTypeExchange
        ? "exchange"
        : this.selectedItemDetails.returnType === this.returnTypeReplace
        ? "replacement"
        : "refund";
    // for (let i = 0; i < reverseTimeline.length; i++) {
    for (let i = reverseTimeline.length - 1; i >= 0; i--) {
      if (reverseTimeline[i].status === latestStatus) {
        reverseTimeline[i].updatedAt = reverseTimeline[i].updatedAt.replace(
          " ",
          "T"
        );
        switch (latestStatus) {
          case "created": {
            this.toolTipMessage = this.datePipe.transform(
              reverseTimeline[i].updatedAt,
              this.dateFormat
            );
            if (
              this.selectedItemDetails.returnType === this.returnTypeExchange
            ) {
              this.remarkMessage =
                "Your request to exchange this item has been raised successfully";
            } else if (
              this.selectedItemDetails.returnType === this.returnTypeReplace
            ) {
              // this.remarkMessage = 'Your request to replace this item has been raised successfully';
              this.remarkMessage =
                "Your request to return this item has been raised successfully. You will be notified as soon as it is approved.";
            } else {
              // this.remarkMessage = 'Your request to return this item has been raised successfully';
              this.remarkMessage =
                "Your request to return this item has been raised successfully. You will be notified as soon as it is approved.";
            }
            break;
          }
          case "approved":
          case "pickup_pending": {
            //case "pickup_pending": case "ready_for_pickup": case "shipment_delayed":
            this.toolTipMessage = this.datePipe.transform(
              reverseTimeline[i].updatedAt,
              this.dateFormat
            );
            if (
              this.selectedItemDetails.returnType === this.returnTypeExchange
            ) {
              this.remarkMessage =
                "Your request to exchange this item has been approved";
            } else if (
              this.selectedItemDetails.returnType === this.returnTypeReplace
            ) {
              // this.remarkMessage = 'Your request to replace this item has been approved';
              this.remarkMessage =
                "Your request to return this item has been approved. You will be notified as the courier partner is out for pickup";
            } else {
              // this.remarkMessage = 'Your request to return this item has been approved';
              this.remarkMessage =
                "Your request to return this item has been approved. You will be notified as the courier partner is out for pickup.";
            }
            break;
          }
          case "carrier_out_for_pick_up_from_customer":
          case "out_for_pickup": {
            this.toolTipMessage = this.datePipe.transform(
              reverseTimeline[i].updatedAt,
              this.dateFormat
            );
            // this.remarkMessage  = 'Our courier partner is out for the pickup.';
            // this.remarkMessage =
            //   "Your item is out for pickup by our courier partner.";
            this.remarkMessage =
              "Our courier partner is arriving today for the return pickup.";
            break;
          }
          // case "shipment_delayed": case "pickup_pending": {
          //   this.toolTipMessage = this.datePipe.transform(reverseTimeline[i].updatedAt, this.dateFormat);
          //   this.remarkMessage = 'Your item is ready to be shipped by our courier partner. You will be notified as soon as it is dispatched.'
          //   break;
          // }
          case "picked_up":
          case "intransit":
          case "out_for_delivery": {
            this.toolTipMessage = this.datePipe.transform(
              reverseTimeline[i].updatedAt,
              this.dateFormat
            );
            if (
              this.selectedItemDetails.returnType === this.returnTypeExchange
            ) {
              this.remarkMessage =
                "Your item has been successfully picked up by our courier partner.";
            } else if (
              this.selectedItemDetails.returnType === this.returnTypeReplace
            ) {
              this.remarkMessage =
                "Your item has been successfully picked up by our courier partner.";
            } else {
              this.remarkMessage =
                "Your item has been successfully picked up by our courier partner.";
            }
            break;
          }
          case "failed_delivery":
          case "pickup_failed":
          case "pickup_cancelled": {
            //case "pickup_cancelled": case "cancelled_order":
            if (
              this.selectedItemDetails.returnType == "replace" ||
              this.selectedItemDetails.returnType == "exchange"
            ) {
              this.remarkMessage =
                "We were unable to pickup your item due to failed attempt.";
            } else {
              this.remarkMessage =
                "We were unable to pickup your item due to failed attempt. You will be notified as soon as our courier partner will re-attempt the pickup.";
            }
            if (
              this.selectedItemDetails.returnType === this.returnTypeExchange ||
              this.selectedItemDetails.returnType === this.returnTypeReplace
            ) {
              this.toolTipMessage = this.datePipe.transform(
                reverseTimeline[i].updatedAt,
                this.dateFormat
              );
            } else {
              this.toolTipMessage = this.datePipe.transform(
                reverseTimeline[i].updatedAt,
                this.dateFormat
              );
            }
            // this.remarkMessage  = 'We are unable to pickup your item.';

            // this.remarkMessage='We were unable to pickup your item due to failed attempt. You will be notified as soon as our courier partner will re-attempt the pickup.';
            //as the courier partner stated "' + reverseTimeline[i].remark + '"';
            break;
          }

          case "ndr_resolution_submitted": {
            const resolutionDetails = JSON.parse(
              JSON.parse(reverseTimeline[i].remark)
            );
            if (
              resolutionDetails.resolutionCode === RESOLUTION_CODE.DELIVERY_DATE
            ) {
              this.toolTipMessage = this.datePipe.transform(
                resolutionDetails.deferredDate,
                this.dateFormat
              );
            } else {
              this.toolTipMessage = this.datePipe.transform(
                reverseTimeline[i].updatedAt,
                this.dateFormat
              );
            }
            this.remarkMessage = this.failedPickupResolutionRemarkMessages(
              resolutionDetails.resolutionCode
            );

            break;
          }
          case "intransit": {
            this.toolTipMessage =
              returnTypeText === "refund"
                ? "Refund Request on " +
                  this.datePipe.transform(
                    reverseTimeline[i].updatedAt,
                    this.dateFormat
                  )
                : returnTypeText +
                  " approved on " +
                  this.datePipe.transform(
                    reverseTimeline[i].updatedAt,
                    this.dateFormat
                  );
            if (returnTypeText === "refund") {
              this.remarkMessage =
                "We have recieved your refund request for this item.";
            } else {
              // this.remarkMessage = '<p>Your item has been successfully recieved and your ' + returnTypeText + ' request has been approved. Please click <a href="/track/' + this.orderData.customerOrderNumber + '/' + this.selectedItemDetails.newOrderItemID + '" >click here</a> to track your ' + returnTypeText + ' item</p>';
              this.remarkMessage =
                "<p>Your " +
                returnTypeText +
                ' request has been approved.<a href="/track/' +
                this.orderData.customerOrderNumber +
                "/" +
                this.selectedItemDetails.newOrderItemID +
                '" >Click here</a> to track your new item</p>';
            }
            break;
          }
          case "warehouse_delivered":
          case "delivered_warehouse":
          case "delivered": {
            this.toolTipMessage = this.datePipe.transform(
              reverseTimeline[i].updatedAt,
              this.dateFormat
            );
            if (this.selectedItemDetails.qc) {
              //TODO change the return type message and give click on button
              // this.remarkMessage = 'Your ' + this.selectedItemDetails.returnType + ' request has been approved.'
            } else {
              this.remarkMessage =
                "Your returned item has failed our quality check, therefore we are unable to process the " +
                this.selectedItemDetails.returnType +
                " Please contact " +
                this.userSettings.supportEmail +
                " for further details.";
            }
            break;
          }
          case "IN_TRANSIT":
          case "PICKED_FROM_CUSTOMER":
          case "shipment_held":
          case "shipment_delayed": {
            this.toolTipMessage = this.datePipe.transform(
              reverseTimeline[i].updatedAt,
              this.dateFormat
            );

            break;
          }
          default: {
            //statements;
            break;
          }
        }
        return [this.toolTipMessage, this.remarkMessage];
      }
    }
  }

  forwardToolAndRemark(latestStatus) {
    const forwardTimeLine = this.selectedItemDetails.forwardTrackingTimeline
      .statusLog;
    for (let i = 0; i < forwardTimeLine.length; i++) {
      if (forwardTimeLine[i].status === latestStatus) {
        // console.log(forwardTimeLine[i].status);
        forwardTimeLine[i].updatedAt = forwardTimeLine[i].updatedAt.replace(
          " ",
          "T"
        );

        switch (latestStatus) {
          case "created": {
            this.toolTipMessage = this.datePipe.transform(
              forwardTimeLine[i].updatedAt,
              this.dateFormat
            );
            // this.remarkMessage = 'Your order has been placed successfully';

            this.remarkMessage =
              "Your order has been placed successfully. You will be notified as soon as it is ready to be shipped.";

            break;
          }
          case "packed":
          case "manifested":
          case "pickup_pending": {
            this.toolTipMessage = this.datePipe.transform(
              forwardTimeLine[i].updatedAt,
              this.dateFormat
            );
            this.remarkMessage =
              "Your item is ready to be shipped by our courier partner. You will be notified as soon as it is dispatched.";
            break;
          }
          case "dispatched":
          case "shipment_delayed": {
            this.toolTipMessage = this.datePipe.transform(
              forwardTimeLine[i].updatedAt,
              this.dateFormat
            );
            this.remarkMessage =
              "Your item has been picked up by our courier partner. You will be notified as soon as it is out for delivery.";
            break;
          }
          case "intransit": {
            this.toolTipMessage = this.datePipe.transform(
              forwardTimeLine[i].updatedAt,
              this.dateFormat
            );
            this.remarkMessage = "Your item has been shipped";
            break;
          }
          case "out_for_delivery": {
            this.toolTipMessage = this.datePipe.transform(
              forwardTimeLine[i].updatedAt,
              this.dateFormat
            );
            this.remarkMessage =
              "Your item is out for delivery by our courier partner."; //Want to return the product?  Confirm Delivery to raise a return request
            break;
          }

          case "failed_delivery":
          case "delivery_failed": {
            this.toolTipMessage = this.datePipe.transform(
              forwardTimeLine[i].updatedAt,
              this.dateFormat
            );
            this.remarkMessage =
              "We were unable to deliver your item due to failed attempt. You will be notified as soon as our courier partner will re-attempt the delivery.."; //Our courier partner stated "' + forwardTimeLine[i].remark + '"';
            break;
          }
          case "ndr_resolution_submitted": {
            const resolutionDetails = JSON.parse(
              JSON.parse(forwardTimeLine[i].remark)
            );
            if (
              resolutionDetails.resolutionCode === RESOLUTION_CODE.DELIVERY_DATE
            ) {
              this.toolTipMessage = this.datePipe.transform(
                resolutionDetails.deferredDate,
                this.dateFormat
              );
            } else {
              this.toolTipMessage = this.datePipe.transform(
                forwardTimeLine[i].updatedAt,
                this.dateFormat
              );
            }
            this.remarkMessage = this.failedDeliveryResolutionRemarkMessages(
              resolutionDetails.resolutionCode
            );

            break;
          }
          case "delivered": {
            this.toolTipMessage = this.datePipe.transform(
              forwardTimeLine[i].updatedAt,
              this.dateFormat
            );
            this.deliveredDate = forwardTimeLine[i].updatedAt
            this.remarkMessage = "Your item has been successfully delivered";

            break;
          }
          default: {
            //statements;
            break;
          }
        }
        return [this.toolTipMessage, this.remarkMessage];
      }
    }
  }

  refundToolAndRemark(latestStatus) {
    const refundTimeLine = this.selectedItemDetails.refundTimeLine.statusLog;
    for (let i = 0; i < refundTimeLine.length; i++) {
      if (refundTimeLine[i].status === latestStatus) {
        refundTimeLine[i].dateTime = refundTimeLine[i].dateTime.replace(
          " ",
          "T"
        );
        switch (latestStatus) {
          // case "created":  {
          //   this.toolTipMessage = this.datePipe.transform(refundTimeLine[i].dateTime, this.dateFormat);
          //   this.remarkMessage = 'Your refund request has been raised successfully.';
          //   break;
          // }

          case "created":
          case "approved": {
            this.toolTipMessage = this.datePipe.transform(
              refundTimeLine[i].dateTime,
              this.dateFormat
            );
            this.remarkMessage =
              "Your refund request has been approved successfully. You will be notified as soon as the refund is initiated.";
            break;
          }

          case "refund_initiated": {
            this.toolTipMessage = this.datePipe.transform(
              refundTimeLine[i].dateTime,
              this.dateFormat
            );
            // this.remarkMessage = 'Refund of ' + this.rupeeSymbol + ' ' + this.selectedItemDetails.customerPrice + ' will be transferred to your ' + this.selectedItemDetails.refundTimeLine.refundMode + ' in 2-7 working days.';
            this.remarkMessage =
              "Refund of " +
              this.rupeeSymbol +
              " " +
              this.selectedItemDetails.customerPrice +
              "  has been initiated and will be transferred to your " +
              this.selectedItemDetails.refundTimeLine.refundMode +
              " within 2-7 working days.  For any further queries, kindly reach out to your financial institution ";
            break;
          }

          case "initiated": {
            this.toolTipMessage = this.datePipe.transform(
              refundTimeLine[i].dateTime,
              this.dateFormat
            );
            // this.remarkMessage = 'Refund of ' + this.rupeeSymbol + ' ' + this.selectedItemDetails.customerPrice + ' will be transferred to your ' + this.selectedItemDetails.refundTimeLine.refundMode + ' in 2-7 working days.';
            this.remarkMessage =
              "Refund of " +
              this.rupeeSymbol +
              " " +
              this.selectedItemDetails.customerPrice +
              "  has been initiated and will be transferred to your " +
              this.selectedItemDetails.refundTimeLine.refundMode +
              " within 2-7 working days.  For any further queries, kindly reach out to your financial institution ";
          }

          default: {
            //statements;
            break;
          }
        }
        return [this.toolTipMessage, this.remarkMessage];
      }
    }
  }

  failedDeliveryResolutionRemarkMessages(resolutionCode) {
    switch (resolutionCode) {
      case RESOLUTION_CODE.REATTEMPT_DELIVERY: {
        this.resolutionMessage =
          "Your request to re-attempt the delivery has been submitted.";
        break;
      }
      case RESOLUTION_CODE.DELIVERY_DATE: {
        this.resolutionMessage =
          "Your request to change the delivery date has been submitted.";
        break;
      }
      case RESOLUTION_CODE.DIFFERENT_ADDRESS: {
        this.resolutionMessage =
          "Your request to change the delivery address has been submitted.";
        break;
      }
      case RESOLUTION_CODE.CONTACT_NUMBER: {
        this.resolutionMessage =
          "Your request to add alternate contact number has been submitted.";
        break;
      }
      case RESOLUTION_CODE.SHIPMENT_RTO: {
        this.resolutionMessage =
          "As per your request this item has been cancelled.Please do not accept if delivery is attempted.";
        break;
      }
      case RESOLUTION_CODE.FALSE_REASON: {
        this.resolutionMessage =
          "Your request has been submitted.Our courier partner will re-attempt this delivery at the earliest.";
        break;
      }
      case RESOLUTION_CODE.LANDMARK: {
        this.resolutionMessage =
          "Your request to add landmark has been submitted.";
        break;
      }
      default: {
        this.resolutionMessage = "Your request has beeen submitted";
        break;
      }
    }
    return this.resolutionMessage;
  }

  failedPickupResolutionRemarkMessages(resolutionCode) {
    switch (resolutionCode) {
      case RESOLUTION_CODE.REATTEMPT_DELIVERY: {
        this.resolutionMessage =
          "Your request to re-attempt the pickup has been submitted.";
        break;
      }
      case RESOLUTION_CODE.DELIVERY_DATE: {
        this.resolutionMessage =
          "Your request to change the pickup date has been submitted.";
        break;
      }
      case RESOLUTION_CODE.DIFFERENT_ADDRESS: {
        this.resolutionMessage =
          "Your request to change the pickup address has been submitted.";
        break;
      }
      case RESOLUTION_CODE.CONTACT_NUMBER: {
        this.resolutionMessage =
          "Your request to add alternate contact number has been submitted.";
        break;
      }
      case RESOLUTION_CODE.SHIPMENT_RTO: {
        this.resolutionMessage =
          "As per your request this item has been cancelled.Please do not accept if pickup is attempted.";
        break;
      }
      case RESOLUTION_CODE.FALSE_REASON: {
        this.resolutionMessage =
          "Your request has been submitted.Our courier partner will re-attempt this pickup at the earliest.";
        break;
      }
      case RESOLUTION_CODE.LANDMARK: {
        this.resolutionMessage =
          "Your request to add landmark has been submitted.";
        break;
      }
      default: {
        this.resolutionMessage = "Your request has beeen submitted";
        break;
      }
    }
    return this.resolutionMessage;
  }

  flushSomeStorageData() {
    LocalStorageService.flush("returnFlowType");
    LocalStorageService.flush("actionType");
    LocalStorageService.flush("cancelItemRequest");
    LocalStorageService.flush("returnItemRequest");
    LocalStorageService.flush("returnCancelItemFormRequest");
    LocalStorageService.flush("failedDeliveryFormRequest");
    LocalStorageService.flush("exchangeOption");
  }

  replaceDate(date) {
    date = date.replace(" ", "T");
    // let isoDate = new Date(date).toISOString();
    return this.datePipe.transform(date, this.dateFormat);
  }
  replaceTime(time) {
    // let timeNew = time.split(" ")[1];
    time = time.replace(" ", "T");
    return this.datePipe.transform(time, " h:mm a");
  }

  replaceDay(date) {
    date = date.replace(" ", "T");
    return this.datePipe.transform(date, " EEE, dd MMM");
  }
  /**
   ** 24-Nov-2019
   ** Move to return cancellation process
   */
  moveToReturnCancellation(itemId) {
    this.router.navigate([
      ROUTES.ORDER_RETURN_CANCELLATION,
      this.orderId,
      itemId
    ]);
  }

  /**
   ** 26-Nov-2019
   ** Moved to failed delivery flow
   */
  moveToFailedDelivery(itemId) {
    LocalStorageService.flush("failedDeliveryFormRequest");
    this.router.navigate([ROUTES.ORDER_FAILED_DELIVERY, this.orderId, itemId]);
  }

  /**
   ** 28-Nov-2019
   ** Moved to failed pickup flow
   */
  moveToFailedPickup(itemId) {
    LocalStorageService.flush("failedPickupFormRequest");
    this.router.navigate([ROUTES.ORDER_FAILED_PICKUP, this.orderId, itemId]);
  }

  handleClick() {
    return true;
  }
  transformDecimal(num) {
    return this.decimalPipe.transform(num, "2.");
  }

  openUpdateModal(val) {
    if (document.getElementById("specificDiv")) {
      var myDiv = document.getElementById("specificDiv");
      myDiv.scrollTop = 0;
    }

    this.expandedIndex = -1;
    if (val == "cancel") {
      this.show_forward_reverse_timeline = true;
      // this.seeUpdateModifyArray([...this.selectedItemDetails.forwardTrackingTimeline.statusLog, ...this.selectedItemDetails.reverseTrackingTimeline.statusLog]);
      this.seeUpdateModifyArray(
        this.selectedItemDetails.forwardTrackingTimeline.statusLog
      );
      this.seeUpdateModifyArray(
        this.selectedItemDetails.reverseTrackingTimeline.statusLog,
        "cancel"
      );
    } else {
      this.show_forward_reverse_timeline = false;
      if (val == "reverse")
        this.seeUpdateModifyArray(
          this.selectedItemDetails.reverseTrackingTimeline.statusLog
        );
      else
        this.seeUpdateModifyArray(
          this.selectedItemDetails.forwardTrackingTimeline.statusLog
        );
    }

    this.isSeeUpdatesOpen = !this.isSeeUpdatesOpen;
    this.isSeeUpdatesOpendelay = !this.isSeeUpdatesOpendelay;
    this.preventScroll.disable();
    document.querySelector("body").classList.add("stop-scrolling");
  }

  closeUpdateModal() {
    document.querySelector("body").classList.remove("stop-scrolling");
    this.isSeeUpdatesOpen = false;
    if (!this.deviceService.isDesktop()) {
      setTimeout(() => {
        this.isSeeUpdatesOpendelay = false;
      }, 350);
    } else this.isSeeUpdatesOpendelay = false;
    this.preventScroll.disablescroll();
    this.updateclick(0);
  }

  // Help Modal
  openHelpModal() {
    // this.preventScroll.disable();
    this.isHelpOpen = !this.isHelpOpen;
    this.isHelpOpendelay = !this.isHelpOpendelay;
    document.querySelector("body").classList.add("stop-scrolling");
  }

  closeHelpModal() {
    // this.preventScroll.enable();
    this.isHelpOpen = false;
    if (!this.deviceService.isDesktop()) {
      setTimeout(() => {
        this.isHelpOpendelay = false;
      }, 350);
    } else this.isHelpOpendelay = false;
    document.querySelector("body").classList.remove("stop-scrolling");
  }

  openSite(siteUrl) {
    window.open(siteUrl, "_blank");
  }
  open_ace_com(siteUrl) {
    window.open(siteUrl, "_blank");
  }
  // getWindowReturnDate() {
  //   // var date = new Date(this.deliveredDate); // Now
  //   // date.setDate(date.getDate() + this.userSettings.returnWindowDays); // Set now + 30 days as the new date

  //   // var d = new Date(this.deliveredDate);
  //   // console.log(this.deliveredDate);

  //   // this.windowDate = this.datePipe.transform(d.toLocaleString(), this.dateFormat);
  //   if (this.deliveredDate) {
  //     var date1: any;
  //     date1 = new Date(this.deliveredDate);
  //     var date2: any;
  //     date2 = new Date();
  //     date1.setDate(date1.getDate() + this.userSettings.returnWindowDays);
  //     let diff = (date2 - date1) / (1000 * 3600 * 24);

  //     // var date1 = new Date(this.deliveredDate);
  //     // var date2 = new Date();
  //     // // this.windowDate = this.datePipe.transform(date1);
  //     this.windowDate = this.datePipe.transform(date1, this.dateFormat);
  //     // var timeDiff = Math.abs(date2.getTime() - date1.getTime());
  //     // var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  //     if (diff <= this.userSettings.returnWindowDays) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // }

  getWindowReturnDate() {
  if (this.deliveredDate) {
    const deliveredDate = new Date(this.deliveredDate);
    const currentDate = new Date();
    // Calculate the return window end date
    deliveredDate.setDate(deliveredDate.getDate() + this.userSettings.returnWindowDays);

    // Compare the current date with the return window end date
    const isWithinReturnWindow = currentDate <= deliveredDate;

    // Format the window date for display
    this.windowDate = this.datePipe.transform(deliveredDate, this.dateFormat);
    return isWithinReturnWindow;
  }
  return false;
}


  // getShipment(status) {
  //   var executed = false;
  //   console.log("selectedItemDetails.forwardTrackingTimeline-------",this.selectedItemDetails.forwardTrackingTimeline)
  //   if (!executed) {
  //     executed = true;
  //     if (this.shipped.includes(status)) {
  //       return (this.shipped_delay_message =
  //         "222Your item has been picked up by our courier partner. You will be notified as soon as it is out for delivery.");
  //     }
  //     // else if(status=='dispatched'){
  //     //   return this.shipped_delay_message = "Your item has been picked up by our courier partner. You will be notified as soon as it is out for delivery."

  //     // }
  //     else if (this.out_for_delivery.includes(status)) {
  //       return (this.shipped_delay_message =
  //         "Your item is out for delivery by our courier partner.");
  //     }
  //   }
  // }

  getShipment(selectedItemDetails) {
    var executed = false;
    for (
      let i = 0;
      i < selectedItemDetails.forwardTrackingTimeline.statusLog.length;
      i++
    ) {
      if (
        !executed &&
        this.shipment_delay.includes(
          selectedItemDetails.forwardTrackingTimeline.statusLog[i].status
        )
      ) {
        executed = true;
        if (
          this.shipped.includes(
            selectedItemDetails.forwardTrackingTimeline.statusLog[i - 1].status
          )
        ) {
          return (this.shipped_delay_message =
            "Your item has been picked up by our courier partner. You will be notified as soon as it is out for delivery.");
        } else if (
          this.out_for_delivery.includes(
            selectedItemDetails.forwardTrackingTimeline.statusLog[i].status
          )
        ) {
          return (this.shipped_delay_message =
            "Your item is out for delivery by our courier partner.");
        }
      }
    }
  }
  getShipmentStatus(status) {
    var executed = false;
    if (!executed) {
      executed = true;
      if (this.shipped.includes(status)) {
        return "Shipped";
      } else if (this.out_for_delivery.includes(status)) {
        return "Out For Delivery";
      }
    }
  }
  getPickupDelay(status) {
    var executed = false;
    if (!executed) {
      executed = true;
      if (this.requested.includes(status)) {
        return "Your request to return this item has been raised successfully. You will be notified as soon as it is approved.";
      } else if (this.approved.includes(status)) {
        return (this.pickup_delay_message =
          "Your request to return this item has been approved. You will be notified as the courier partner is out for pickup.");
      } else if (this.pickup.includes(status)) {
        return (this.pickup_delay_message =
          "Your item is out for pickup by our courier partner.");
      }
    }
  }
  getPickupDelay1(status) {
    var executed = false;
    if (!executed) {
      executed = true;
      if (this.requested.includes(status)) {
        return "Return requested";
      } else if (this.approved.includes(status)) {
        return "Request approved";
      } else if (this.pickup.includes(status)) {
        return "Out for pickup";
      }
    }
  }

  mouseEnter(value) {
    this.color = true;
  }

  mouseLeave(value) {
    this.color = false;
  }
  getStatus(status, timeline) {
    status = status.replace(/[0-9]/g, "");
    if (status == "created") {
      if (timeline == "forward") {
        return "Order placed";
      } else if (timeline == "reverse") {
        return "Return created";
      }
    } else if (status == "order_placed" && timeline == "forward") {
      return "Order placed";
    } else if (status == "accepted") {
      return "Order accepted";
    } else if (status == "manifested") {
      return "Order manifested";
    } else if (status == "packed") {
      return "Order packed";
    } else if (
      (status == "picked" || status == "picked_up") &&
      timeline == "forward"
    ) {
      return "Picked up";
    } else if (status == "shipped") {
      return "Order shipped";
    } else if (status == "ready_to_ship") {
      return "Order is ready to ship";
    } else if (status == "dispatched") {
      return "Order dispatched";
    } else if (status == "intransit") {
      return "Order intransit";
    } else if (status == "shipment_delayed") {
      return "Shipment has been delayed";
    } else if (status == "delivered") {
      return "Order delivered";
    } else if (status == "out_for_delivery") {
      return "Order is out for delivery";
    } else if (status == "ndr_resolution_submitted") {
      return "Delivery requested by customer";
    } else if (status == "rto" || status == "cancelled") {
      return "Order cancelled";
    } else if (status == "rto_intransit") {
      return "Return In-transit";
    } else if (status == "rto_created") {
      return "Return created";
    } else if (status == "rto_delivered_warehouse") {
      return "Return delivered to warehouse";
    } else if (status == "rto_out_for_delivery") {
      return "Return is out for delivery";
    } else if (status == "rto_shipment_delay") {
      return "Return shipment is delayed";
    } else if (status == "rto_requested") {
      return "Return requested";
    } else if (status == "not_serviceable") {
      return "Delivery not serviceable";
    } else if (status == "failed_delivery" || status == "delivery_failed") {
      if (status == "failed_delivery" && timeline == "forward") {
        return "Failed delivery attempt";
      } else if (status == "failed_delivery" && timeline == "reverse") {
        return "Failed pickup attempt";
      }
    } else if (status == "pickup_pending") {
      /*---------------------------------Return----------------------------*/
      return "Pickup pending";
    } else if (status == "out_for_pickup") {
      return "Out for pickup";
    } else if (status == "order_placed" && timeline == "reverse") {
      return " Return Order placed";
    } else if (status == "approved") {
      return "Return approved";
    } else if (
      (status == "picked" || status == "picked_up") &&
      timeline == "reverse"
    ) {
      return "Return picked up";
    } else if (status == "pickup_cancelled") {
      return "Pickup failed";
    } else if (status == "return_cancelled") {
      return "Return cancelled";
    } else if (
      status == "delivered_warehouse" ||
      status == "warehouse_delivered"
    ) {
      return "Delivered warehouse";
    } else {
      return status;
    }
  }
  moveToMarkAsDeliveredPopup(itemId) {
    LocalStorageService.set("actionType", "markAsDelivered");

    console.log(localStorage);
    this.orderReturnService.generateOtp().subscribe(data => {
      console.log(data);
    });
    this.router.navigate([ROUTES.OTP_CONFIRMATION, this.orderId, itemId]);

    console.log(itemId);
    console.log(this.orderData);
    let item = this.orderData.items.find(p => p.orderItemID === itemId);
    console.log(item);
    let timestamp = +Date.now();
    let cur = moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
    console.log(cur);
    let data = {
      actor: localStorage.getItem("clientPortalemail") ? localStorage.getItem("clientPortalemail"): LocalStorageService.get('actorcutomername'),
      actorType: localStorage.getItem("clientPortalemail") ? 'seller': 'buyer',
      status_description: "shipment_Delivered",
      waybill: item.forwardTrackingTimeline.trackingID,
      cp_name: item.forwardTrackingTimeline.courierName,
      status: "Delivered",
      location: "",
      status_code: 8,
      additional: {
        latest_status: {
          status_description: "Delivered",
          remark: "Customer manually delivered the order via OTP verification",
          status: "Delivered",
          status_code: 8,
          location: "",
          timestamp: cur
        }
      },
      timestamp: cur,
      remark: "Delivered"
    };
    LocalStorageService.set("markAsDeliveredFormRequest", data);

    this.isCancelOpen = true;
    this.preventScroll.disable();
    // console.log("Yayay");
  }
  // closeCancelModal() {
  //   this.isCancelOpen = false;
  //   this.preventScroll.enable();
  // }

  updateclick(i) {
    if (this.currentindex === i) {
      this.currentindex = null;
    } else {
      this.currentindex = i;
    }
  }
  closetosterclick() {
    this.CancelItemToster = false;
    localStorage.removeItem("cancelItemRequestTrue");
    LocalStorageService.flush("cancelItemRequestTrue");
    this.failedDeliveryRequest = false;
    localStorage.removeItem("failedDeliverTostermsg");
    LocalStorageService.flush("failedDeliverTostermsg");
    localStorage.removeItem("resolutionCode");
    LocalStorageService.flush("resolutionCode");
    this.returnItemToster = false;
    LocalStorageService.flush("returnRequest");
    this.editdetailsRequest = false;
    LocalStorageService.flush("editdetailsTostermsg");
  }
  getStatusDate(value, status) {
    let statusDate: any;
    let statusLength: number;
    for (let key in value) {
      if (key.startsWith(status)) {
        statusLength = value[key].length - 1;
        statusDate = value[key][statusLength].updatedAt;
      }
    }
    return statusDate;
  }

  copyInputOda(val: string) {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }


  showEditDetails(){
    if(this.auth.getTokenType() == 'partial') {
      this.sendOTP();
      return;
    }
    this.alertService.openAddressPopup(true);
    this.userdetailsinfo = false;
    // OrderSidebarComponent.deliveryPopupdelay = true;
    // OrderSidebarComponent.deliveryPopup = true;
    // LocalStorageService.set("editdetails", true);
    // LocalStorageService.set("Orderdetails", this.orderData);
    // document.querySelector("body").classList.add("hide-footer");
  }

  opendialog() {
    let draftDialogRef = this.dialog.open(InformationPopoverComponent, {
      panelClass: ['back-confirm-dialog', 'backpop'],
    });

  }
  closediaog() {
    this.dialog.closeAll();
  }
  userdetailclick(){
    this.userdetailsinfo = true;
  }
  userdetailclose(e){
    this.userdetailsinfo = false;
  }

  sendOTP() {
    this.router.navigate([ROUTES.VERIFY_OTP_CONFIRMATION, this.orderId]);
  }
}
