"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var router_2 = require("@angular/router");
var rxjs_1 = require("rxjs");
var constants_1 = require("../../constants/constants");
var store_1 = require("@ngrx/store");
var index_1 = require("../../services/index");
var order_placed_actions_1 = require("../../../order-placed/actions/order-placed.actions");
var selectors_1 = require("../../../order-placed/reducers/selectors");
var services_1 = require("../../services");
var common_1 = require("@angular/common");
var ngx_device_detector_1 = require("ngx-device-detector");
var order_placed_actions_2 = require("../../../order-placed/actions/order-placed.actions");
var selectors_2 = require("../../../order-placed/reducers/selectors");
var OrderSidebarComponent = /** @class */ (function () {
    function OrderSidebarComponent(decimalPipe, router, store, alertService, route, deviceService, datePipe, auth) {
        var _this = this;
        this.decimalPipe = decimalPipe;
        this.router = router;
        this.store = store;
        this.alertService = alertService;
        this.route = route;
        this.deviceService = deviceService;
        this.datePipe = datePipe;
        this.auth = auth;
        this.rupeeSymbol = constants_1.RUPEE_SYMBOL;
        this.selectedItemData = new core_1.EventEmitter();
        this.editdetailsevent = new core_1.EventEmitter();
        this.subscriptions$ = new rxjs_1.Subscription();
        // remarkStatusArray = REVERSE_STATUS.REMARK_REVERSE_STATUS;
        this.remarkStatusArray = constants_1.REVERSE_STATUS.REMARK_REVERSE_STATUS_DUMMY;
        this.warehouse_status = constants_1.REVERSE_STATUS.WAREHOUSE_STATUS;
        // forwardIntransitStatus = FORWARD_STATUS.INTRANSIT_ALL_STATUS;
        // reverseIntransitStatus = REVERSE_STATUS.INTRANSIT_ALL_STATUS;
        this.reverseIntransitStatus = constants_1.REVERSE_STATUS.INTRANSIT_ALL_STATUS;
        this.forwardIntransitStatus = constants_1.FORWARD_STATUS.FORWARD_SHIPPED_STATUS;
        this.cancellationStatusArray = constants_1.FORWARD_STATUS.CANCEL_STATUS;
        this.returnTypeExchange = constants_1.RETURN_TYPE_EXCHANGE;
        this.returnTypeReplace = constants_1.RETURN_TYPE_REPLACE;
        this.changeleftPanelValue = new core_1.EventEmitter();
        this.updatedAddr = false;
        this.deliveryPopupdelay = false;
        this.deliveryPopup = false;
        this.verifyOTP = false;
        this.alertService.openPopup$.subscribe(function (data) {
            // console.log(this.deliveryPopupdelay, this.deliveryPopup,"data");
            if (_this.deliveryPopupdelay == false && _this.deliveryPopup == false && data == true) {
                // console.log(data,"data");
                _this.showEditDetails();
                // this.alertService.openAddressPopup(false);
            }
            // And he have data here too!
        });
        this.verifyOTP = this.auth.getTokenType() == 'partial' ? true : false;
        // localStorage.removeItem('partialVisit')
    }
    OrderSidebarComponent.prototype.ngOnInit = function () {
        var _this = this;
        // LocalStorageService.set('otpVerified','true')
        if (index_1.LocalStorageService.get('otpVerified')) {
            this.updatedAddr = true;
            setTimeout(function () {
                ;
                _this.updatedAddr = false;
                index_1.LocalStorageService.flush('otpVerified');
            }, 5000);
        }
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isDesktopDevice = this.deviceService.isDesktop();
        // console.log('isDesktopDevice',this.isDesktopDevice);
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        // console.log(this.deviceInfo);
        // console.log(this.deviceInfo.os);
        this.orderId = this.route.snapshot.paramMap.get('orderId') ? this.route.snapshot.paramMap.get('orderId') : '';
        this.orderItemId = this.route.snapshot.paramMap.get('orderItemId') ? this.route.snapshot.paramMap.get('orderItemId') : '';
        if (this.orderId) {
            this.selectedItemId = this.orderItemId;
            //Fetch data from state if already exist
            this.store.select(function (state) { return state.orderPlaced.orderPlaced; }).subscribe(function (result) {
                if (result) {
                    _this.orderData = result;
                    _this.settrackingid(_this.orderData);
                    _this.customerOrderNumber = _this.orderData.customerOrderNumber;
                    _this.addressFormValue = _this.orderData.shippingAddress;
                    index_1.LocalStorageService.set('actorcutomername', _this.orderData.billingAddress.customerName);
                    index_1.LocalStorageService.set('channelSlug', _this.orderData.channelSlug);
                    if (_this.isMobile && _this.auth.getTokenType() == 'partial' && localStorage.getItem('partialVisit') == null && localStorage.getItem('partialVisit') != 'true') {
                        localStorage.setItem('partialVisit', 'true');
                        _this.onClickItems(_this.orderData['items'][0]);
                    }
                    _this.defaultSelectedItem(_this.orderData);
                    if (localStorage.getItem("action")) {
                        _this.navigateNdrpage(_this.orderData);
                    }
                }
                else {
                    _this.store.dispatch(new order_placed_actions_1.GetOrder(_this.orderId));
                    _this.subscriptions$.add(_this.store.pipe(store_1.select(selectors_1.getCurrentOrder)).subscribe(function (result1) {
                        if (result1) {
                            _this.orderData = result1;
                            _this.settrackingid(_this.orderData);
                            index_1.LocalStorageService.set('actorcutomername', _this.orderData.billingAddress.customerName);
                            index_1.LocalStorageService.set('channelSlug', _this.orderData.channelSlug);
                            _this.defaultSelectedItem(_this.orderData);
                            if (_this.isMobile && _this.auth.getTokenType() == 'partial' && localStorage.getItem('partialVisit') == null && localStorage.getItem('partialVisit') != 'true') {
                                localStorage.setItem('partialVisit', 'true');
                                _this.onClickItems(_this.orderData['items'][0]);
                            }
                            if (localStorage.getItem("action")) {
                                _this.navigateNdrpage(_this.orderData);
                            }
                        }
                        else {
                            _this.orderData = [];
                            _this.selectedItemDetails = [];
                        }
                    }));
                }
            });
        }
        else {
            this.alertService.showError("Invalid Url");
        }
        //detect device LOC added on 29-Nov-2019
        // if (this.isMobile || this.isTablet) {
        //     this.changeleftPanelValue.emit(false);
        // }else {
        //     this.changeleftPanelValue.emit(true);
        // }
        this.store.dispatch(new order_placed_actions_2.GetUserSettings());
        this.subscriptions$.add(this.store.pipe(store_1.select(selectors_2.getUserSettings)).subscribe(function (result) {
            if (result) {
                _this.userSettings = result;
            }
            else {
                _this.userSettings = [];
            }
        }));
    };
    OrderSidebarComponent.prototype.isEmptyKeys = function (obj) {
        return Object.values(obj).every(function (x) { return x === ""; });
    };
    OrderSidebarComponent.prototype.defaultSelectedItem = function (orderData) {
        this.selectedItemId = this.selectedItemId ? this.selectedItemId : orderData.items[0].orderItemID;
        for (var i = 0; i < orderData.items.length; i++) {
            if (orderData.items[i].orderItemID === this.selectedItemId) {
                this.selectedItemData.emit(orderData.items[i]);
                if (orderData.items[i].latestStatus == 'cancelled' || orderData.items[i].latestStatus == 'packed') {
                    this.trackingID = false;
                }
                this.orderItemExist = true;
                this.selectedItemDetails = orderData.items[i];
                // this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, this.selectedItemId]);
                index_1.LocalStorageService.set("processOrderItemDetails", orderData.items[i]);
            }
        }
        //if no order item id matched then show first item of order
        if (!this.orderItemExist) {
            this.selectedItemData.emit(orderData.items[0]);
            this.selectedItemId = orderData.items[0].orderItemID;
            this.selectedItemDetails = orderData.items[0];
            // this.router.navigate([ROUTES.ORDER_PLACED, this.orderId, this.selectedItemId]);
        }
    };
    OrderSidebarComponent.prototype.settrackingid = function (orderData) {
        var _this = this;
        // New  = created
        // Processing = accepted
        // Failed To RTS = failed_to_rts
        // Unfulfillable = Unfulfillable
        if (orderData && orderData.length > 0) {
            orderData.items.filter(function (element) {
                if (element.latestStatus == 'created' || element.latestStatus == 'accepted' ||
                    element.latestStatus == 'failed_to_rts' || element.latestStatus == 'picked') {
                    _this.editaddresbutton = true;
                }
                var trackingid = element.forwardTrackingTimeline.trackingID;
                if (trackingid) {
                    _this.trackingID = false;
                }
                else {
                    _this.trackingID = true;
                }
            });
        }
        // orderData.items.forEach(element => {
        //   if(element.latestStatus == 'cancelled' || element.latestStatus == 'packed'){
        //     this.trackingID= false
        //   }
        //    const trackingid =  element.forwardTrackingTimeline.trackingID
        //    if(trackingid){
        //     this.trackingID = false
        //    }else{
        //     this.trackingID = true
        //    }
        //  });
    };
    OrderSidebarComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions$) {
            this.subscriptions$.unsubscribe();
        }
    };
    OrderSidebarComponent.prototype.onClickItems = function (item) {
        if (item.latestStatus == 'created' || item.latestStatus == 'accepted' ||
            item.latestStatus == 'failed_to_rts' || item.latestStatus == 'picked') {
            this.editaddresbutton = true;
        }
        else {
            this.editaddresbutton = false;
        }
        if (item.latestStatus == 'cancelled' || item.latestStatus == 'packed') {
            this.trackingID = false;
        }
        else {
            this.trackingID = true;
        }
        var trackingid = item.forwardTrackingTimeline.trackingID;
        if (trackingid) {
            this.trackingID = false;
        }
        else {
            this.trackingID = true;
        }
        index_1.LocalStorageService.flush("defaultSelected");
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('top-header');
        // if (this.deviceInfo.browser === 'Safari' && (this.deviceInfo.device === 'iPad' || this.deviceInfo.os === 'Mac')){  //this.deviceInfo.os === 'Mac' &&
        //   this.changeleftPanelValue.emit(false);
        // }else if (this.isMobile  || this.isTablet){
        //   this.changeleftPanelValue.emit(false);
        // }else {
        //   this.changeleftPanelValue.emit(true);
        // }
        if (this.deviceInfo.browser === 'Safari' && this.isDesktopDevice && this.deviceInfo.os === 'Mac') {
            this.changeleftPanelValue.emit(false);
        }
        else if (this.isDesktopDevice) {
            this.changeleftPanelValue.emit(true);
        }
        else {
            this.changeleftPanelValue.emit(false);
        }
        this.selectedItemId = item.orderItemID;
        this.router.navigate([constants_1.ROUTES.ORDER_PLACED, this.orderId, this.selectedItemId]);
        this.selectedItemDetails = item;
        this.selectedItemData.emit(item);
    };
    OrderSidebarComponent.prototype.replaceDateOld = function (date) {
        return this.datePipe.transform(date, "d MMM , y");
    };
    OrderSidebarComponent.prototype.replaceDate = function (date) {
        // console.log("Order date",date);
        var dateNew = date.split(" ")[0];
        return this.datePipe.transform(dateNew, "d MMM, y");
    };
    OrderSidebarComponent.prototype.isBadInventory = function (item) {
        item.reverseTrackingTimeline.statusLog.forEach(function (element) {
            if (element.status === 'delivered' && item.qc && item.qcReason == 'bad_inventory') {
                return true;
            }
            else {
                return false;
            }
        });
    };
    OrderSidebarComponent.prototype.showEditDetails = function () {
        if (this.auth.getTokenType() == 'partial') {
            this.sendOTP();
            return;
        }
        this.deliveryPopupdelay = true;
        this.deliveryPopup = true;
        index_1.LocalStorageService.set("editdetails", true);
        index_1.LocalStorageService.set("Orderdetails", this.orderData);
        document.querySelector("body").classList.add("hide-footer");
    };
    OrderSidebarComponent.prototype.closeEditAddresspopup = function (event) {
        var _this = this;
        document.querySelector("body").classList.remove("hide-footer");
        // console.log("ok")
        this.deliveryPopup = false;
        document.querySelector("body").classList.remove("stop-scrolling");
        if (!this.deviceService.isDesktop()) {
            setTimeout(function () {
                _this.deliveryPopupdelay = false;
            }, 350);
        }
        else
            this.deliveryPopupdelay = false;
    };
    OrderSidebarComponent.prototype.editdetailspaydataevent = function (event) {
        var _this = this;
        if (this.orderId) {
            this.selectedItemId = this.orderItemId;
            //Fetch data from state if already exist
            this.store.dispatch(new order_placed_actions_1.GetOrder(this.orderId));
            this.store.select(function (state) { return state.orderPlaced.orderPlaced; }).subscribe(function (result) {
                if (result) {
                    _this.orderData = result;
                    _this.settrackingid(_this.orderData);
                    if (_this.orderData) {
                        index_1.LocalStorageService.set('otpVerified', 'true');
                        _this.updatedAddr = true;
                        index_1.LocalStorageService.set('editdetailsTostermsg', true);
                        _this.editdetailsevent.emit();
                        setTimeout(function () {
                            ;
                            _this.updatedAddr = false;
                            index_1.LocalStorageService.flush('otpVerified');
                        }, 5000);
                    }
                    index_1.LocalStorageService.set('actorcutomername', _this.orderData.billingAddress.customerName);
                    index_1.LocalStorageService.set('channelSlug', _this.orderData.channelSlug);
                    //  this.defaultSelectedItem(this.orderData);
                }
                else {
                    _this.store.dispatch(new order_placed_actions_1.GetOrder(_this.orderId));
                    _this.subscriptions$.add(_this.store.pipe(store_1.select(selectors_1.getCurrentOrder)).subscribe(function (result1) {
                        if (result1) {
                            _this.orderData = result1;
                            _this.settrackingid(_this.orderData);
                            if (_this.orderData) {
                                _this.updatedAddr = true;
                                index_1.LocalStorageService.set('editdetailsTostermsg', true);
                                _this.editdetailsevent.emit();
                                setTimeout(function () {
                                    ;
                                    _this.updatedAddr = false;
                                    index_1.LocalStorageService.flush('otpVerified');
                                    // LocalStorageService.flush('editdetailsTostermsg');
                                }, 5000);
                            }
                            index_1.LocalStorageService.set('actorcutomername', _this.orderData.billingAddress.customerName);
                            index_1.LocalStorageService.set('channelSlug', _this.orderData.channelSlug);
                        }
                        else {
                            _this.orderData = [];
                            _this.selectedItemDetails = [];
                        }
                    }));
                }
            });
        }
        else {
            this.alertService.showError("Invalid Url");
        }
    };
    OrderSidebarComponent.prototype.transformDecimal = function (num) {
        return this.decimalPipe.transform(num, "2.");
    };
    // navigateNdrpage(DATA){
    //   LocalStorageService.set("Orderdetails", DATA);
    //   const shipmentId = localStorage.getItem('shipmentId')
    //   DATA.items.filter(itemid => {
    //     if(itemid.shipmentId = shipmentId){
    //         this.router.navigate([ROUTES.ORDER_FAILED_DELIVERY, this.orderId, itemid.orderItemID]);
    //     }
    //   })
    // }
    OrderSidebarComponent.prototype.navigateNdrpage = function (DATA) {
        var _this = this;
        if (localStorage.getItem('orderIdURL') && localStorage.getItem('action')) {
            DATA.items.filter(function (element) {
                if (element.latestStatus == 'created' || element.latestStatus == 'accepted' ||
                    element.latestStatus == 'failed_to_rts' || element.latestStatus == 'picked') {
                    // console.log("++++======",this.orderData)
                    _this.showEditDetails();
                }
            });
        }
        // console.log(DATA)
        index_1.LocalStorageService.set("Orderdetails", DATA);
        var shipmentId = localStorage.getItem('shipmentId');
        if (DATA.items.length > 1) {
            DATA.items.filter(function (itemid) {
                if (itemid.shipmentId == shipmentId && (itemid.latestStatus == 'delivery_failed' || itemid.latestStatus == 'failed_delivery')) {
                    _this.router.navigate([constants_1.ROUTES.ORDER_FAILED_DELIVERY, _this.orderId, itemid.orderItemID]);
                }
            });
        }
        else {
            DATA.items.filter(function (itemid) {
                if (itemid.shipmentId == shipmentId && (itemid.latestStatus == 'delivery_failed' || itemid.latestStatus == 'failed_delivery')) {
                    _this.router.navigate([constants_1.ROUTES.ORDER_FAILED_DELIVERY, _this.orderId, itemid.orderItemID]);
                }
            });
        }
    };
    OrderSidebarComponent.prototype.sendOTP = function () {
        this.router.navigate([constants_1.ROUTES.VERIFY_OTP_CONFIRMATION, this.orderId]);
    };
    return OrderSidebarComponent;
}());
exports.OrderSidebarComponent = OrderSidebarComponent;
